<script>
import _ from "lodash";
import { EventBus } from "@/EventBus";
import BackButtonHandler from "@/components/BackButtonHandler";

export default {
  mixins: [BackButtonHandler],
  props: {
    content: {
      type: Object,
      required: true
    },
    id: {
      type: [String, Number],
      required: false,
      default: -1
    },
    formAction: {
      required: true,
      type: String
    },
    checkedContents: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      contentForm: {},
      contentFormInfo: {},
      openModal: false,
      importAdd: false,
      contentUpdated: false,
      moveToFolderDialog: false
    };
  },

  computed: {
    contentNotChanged() {
      return _.isEqual(this.contentForm, this.content);
    },
    isArchivedStatus() {
      // console.log("isArchivedStatus " + this.contentForm.is_archived);
      if (this.contentForm.is_archived) {
        return true;
      }
      return false;
    },
    multipleItemsSelectedCount() {
      return this.checkedContents.length;
    }
  },

  methods: {
    initialize() {
      this.contentForm = _.cloneDeep(this.content);
      this.contentFormInfo = _.cloneDeep(this.content);
    },

    notifyClosingOfModal(done) {
      EventBus.$emit("modal-cover-content", false);
      done();
    },

    handleOpenModal(value) {
      EventBus.$emit("modal-cover-content", value);
      this.openModal = value;
    },

    handleImportModal(value) {
      EventBus.$emit("modal-cover-content", value);
      this.importAdd = value;
    },

    backButtonHandler() {
      if (this.openModal) {
        this.handleCancel();
        return false;
      }
      return true;
    },

    handleCancel() {
      this.contentForm = _.cloneDeep(this.content);
      this.handleOpenModal(false);
      this.handleImportModal(false);
      this.$emit("handleCancel");
    },

    handleSuccess() {
      EventBus.$emit("list-changed");
      this.handleCancel();
    },

    handleEditBtnClick() {
      this.$emit("edit-item"); //formAction = edit
      this.handleOpenModal(true);
    },

    handleContentUpdatedEvent(value) {
      this.contentUpdated = value;
    },

    handleFormAction() {
      if (this.formAction) {
        if (
          this.formAction === "edit" ||
          this.formAction === "add" ||
          this.formAction === "view"
        ) {
          this.handleOpenModal(true);
        }
        if (this.formAction === "import") {
          this.handleImportModal(true);
        }
      }
    }
  },

  created() {
    EventBus.$on("open-create-modal", () => {
      this.openModal = true;
    });
  },

  beforeDestroy() {
    EventBus.$off("open-create-modal");
  },

  watch: {
    content: {
      immediate: true,
      deep: true,
      handler: "initialize"
    },

    formAction: {
      immediate: true,
      handler: "handleFormAction"
    },

    contentForm: {
      deep: true,
      handler: function() {
        this.handleContentUpdatedEvent(!this.contentNotChanged);
      }
    }
  }
};
</script>

<style scoped></style>
