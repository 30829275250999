<script>
export default {
  created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      // to detect if route change is triggered by a back button click
      const IsItABackButton = window.popStateDetected;
      window.popStateDetected = false;

      next(this.backButtonHandler(to, from, IsItABackButton));
    });

    this.$once("hook:destroyed", () => {
      unregisterRouterGuard();
    });
  }
};
</script>
