<template>
  <font-awesome-icon
    @click="valueToCopy"
    :icon="['fas', 'clone']"
    class="fa-md cell-item-pointer"
  />
</template>

<script>
import _ from "lodash";
export default {
  name: "CopierComponent",
  props: {
    value: {
      required: true,
      type: String
    },
    successMessageOverride: {
      required: false,
      type: String
    },
    errorMessageOverride: {
      required: false,
      type: String
    },
    valueFromServer: {
      type: Function,
      default: null
    }
  },
  methods: {
    async valueToCopy() {
      try {
        let value;
        // get value from backend
        if (this.valueFromServer) {
          value = await this.valueFromServer(this.value);
        } else {
          // use passed in value
          value = this.value;
        }
        const success = await this.$clipboard(value);
        success
          ? this.clipboardSuccessHandler(value)
          : this.clipboardErrorHandler();
      } catch (error) {
        this.clipboardErrorHandler();
      }
    },
    clipboardSuccessHandler(value) {
      const message = _.isEmpty(this.successMessageOverride)
        ? value
        : this.successMessageOverride;
      this.$message.success(message);
    },
    clipboardErrorHandler() {
      const message = _.isEmpty(this.errorMessageOverride)
        ? __("copy failed - please copy manually")
        : this.errorMessageOverride;
      this.$message.error(message);
    }
  }
};
</script>

<style scoped></style>
