<template>
  <el-container style="max-height: 100vh; height: 100%">
    <el-container>
      <slot name="list"></slot>
    </el-container>
    <el-aside
      v-if="editable && formAction != 'blank'"
      class="form-aside relative"
      :width="`${asideWidth}px`"
    >
      <div class="aside-content" v-if="formAction.length === 0">
        <img
          :src="require('@/assets/images/asidedefault_new.svg')"
          width="60%"
        />
        <div style="font-size: 14px;padding: 10px 20px;color: #A0A8B5">
          {{ asideText }}
        </div>
      </div>
      <slot name="form"></slot>
    </el-aside>
    <el-aside
      v-if="formAction === 'blank'"
      class="form-aside relative"
      :width="`${asideWidth}px`"
    >
    </el-aside>
  </el-container>
</template>

<script>
export default {
  name: "MainContentNewIndex",
  props: {
    formAction: {
      default: ""
    },
    asideText: {
      default: __("Select an item to edit and view more details.")
    },
    asideButtonText: {
      default: __("Create")
    },
    asideWidth: {
      type: Number,
      required: false,
      default: 284
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    }
  }
};
</script>

<style scoped lang="scss">
.h-full {
  height: 100%;
}

.aside-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.form-aside {
  background: white;
  border-left: #f5f5f8 1px solid;
}
</style>
