<template>
  <el-button v-bind="$attrs" v-on="$listeners" :type="type">{{
    buttonText
  }}</el-button>
</template>

<script>
export default {
  name: "SaveButton",
  props: {
    variant: {
      type: String,
      default_value: "CreateUpdate"
    },
    primaryKey: {
      type: Number,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: "default"
    }
  },
  data() {
    return {
      variants: {
        CreateUpdate: {
          create: __("Create"),
          update: __("Update")
        },
        AddUpdate: {
          create: __("Add"),
          update: __("Update")
        },
        AssignUpdate: {
          create: __("Assign"),
          update: __("Update")
        },
        UpdateSave: {
          create: __("Save"),
          update: __("Update")
        }
      }
    };
  },
  computed: {
    buttonText: function() {
      if (this.variants[this.variant] !== undefined) {
        return this.primaryKey !== -1
          ? this.variants[this.variant].update
          : this.variants[this.variant].create;
      }

      return this.primaryKey !== -1
        ? this.variants["CreateUpdate"].update
        : this.variants["CreateUpdate"].create;
    }
  }
};
</script>

<style scoped></style>
